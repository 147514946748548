<template>
  <div class="flyer" v-if="flyer">
    <page-title class="hidden-xs-only" :page="page" />
    <Breadcrumb class="d-none d-sm-flex px-0" :items="vBreadcrums" />
    <v-container class="flyers-title">
      <h2 v-html="flyer.title.rendered" @click="clicked"></h2>
      <div class="d-flex align-center justify-space-between">
        <div v-html="flyer.testo_validit" @click="clicked"></div>
        <v-btn
          color="primary"
          depressed
          class="text-uppercase hidden-xs-only"
          :to="{
            name: 'Flyers'
          }"
        >
          {{ $t("flyer.back") }}
        </v-btn>
      </div>
    </v-container>

    <v-container class="upper">
      <iframe :src="iframeSrc" style="" class="mb-sm-15" />
    </v-container>

    <v-btn
      class="d-flex d-sm-none mt-8 mb-16 mx-auto"
      color="primary"
      elevation="0"
      max-width="260"
      dense
      :to="{
        name: 'Flyers'
      }"
    >
      {{ $t("flyer.back") }}
    </v-btn>
    <div class="secondary pb-5">
      <ProductListSlider
        title="Scopri le nostre promozioni"
        paginationClass="swiper-pagination-promo"
        :key="key"
        :promo="true"
        :shuffle="true"
        position="flyer_in_promozione"
      />
    </div>
    <!-- INDUSTRY BANNER -->
    <v-container v-if="industryBanner">
      <ProposalSlider
        :title="$t('industryBanner.title')"
        :slidesPerView="2"
        :paginationClass="'swiper-pagination-industry'"
        :proposals="industryBanner"
      />
    </v-container>
    <BeCustomerCardHorizontal card="registration" />
    <newsletter-horizontal />
  </div>
</template>
<style scoped lang="scss">
.upper {
  iframe {
    // margin-top: 84px;
    border: 0;
    width: 100%;
    height: calc(100vh - 220px);
    html,
    .main {
      background-color: $white !important;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      // margin-top: 58px;
      max-height: 600px;
    }
    @media (max-width: 400px) {
      max-height: 530px;
    }
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import ProductListSlider from "@/components/product/ProductListSlider.vue";
// import ProposalSlider from "@/components/proposal/ProposalSlider.vue";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import BeCustomerCardHorizontal from "@/components/cards/BeCustomerCardHorizontal.vue";
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";

import CategoryService from "~/service/categoryService";
import CMSService from "~/service/cmService";

import page from "~/mixins/page";
import clickHandler from "~/mixins/clickHandler";

import { replaceHtmlEntities, stripHtml } from "~/service/ebsn";

import get from "lodash/get";

export default {
  // eslint-disable-next-line vue/no-unused-components
  name: "Flyer",
  components: {
    Breadcrumb,
    // ProductListSlider,
    BeCustomerCardHorizontal,
    PageTitle,
    NewsletterHorizontal
    // ProposalSlider
  },
  data() {
    return {
      header: {},
      iframeLoaded: false,
      flyer: null,
      industryBanner: null
    };
  },
  mixins: [page, clickHandler],
  computed: {
    iframeSrc() {
      return (
        "https://grosmarket.it/p/offerta/" +
        this.$route.params.volantino +
        "?notemplate=1"
      );
    },
    vBreadcrums() {
      // let b = this.breadcrumb;
      let b = [];
      b.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      b.push({
        to: {
          name: "Flyers"
        },
        text: "Volantini",
        exact: true
      });
      if (this.flyer) {
        b.push({
          to: {
            name: "Flyer",
            params: {
              volantino: this.$route.params.volantino
            }
          },
          text: this.flyer.title.rendered,
          exact: true
        });
      }
      return b;
    }
  },
  methods: {
    async fetchFlyer(page) {
      let flyers = await CMSService.getCustomPostBySlug("offerta", page);

      if (flyers.length > 0) {
        this.flyer = flyers[0];
        this.key++;
      }
    },
    async fetchCategory(categorySlug) {
      try {
        let vm = this;
        let response = await CategoryService.getCategoryBySlug(categorySlug);
        vm.industryBanner = response.proposals["industry-banner"];
        console.log(vm.industryBanner);
      } catch (e) {
        console.log(e);
      }
    }
  },
  metaInfo() {
    let data = this.flyer;
    return {
      title: get(
        data,
        "meta.seo_title",
        data
          ? replaceHtmlEntities(stripHtml(data.title && data.title.rendered))
          : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(data, "meta.seo_description")
        }
      ]
    };
  },
  async mounted() {
    // this.fetchPage("volantini");
    this.page = this.$route.params.page;
    await this.fetchFlyer(this.$route.params.volantino);
    this.fetchCategory("industry-banner");
  },
  watch: {
    "$route.params.volantino": function(volantino) {
      this.fetchFlyer(volantino);
    }
  }
};
</script>
